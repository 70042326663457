@import 'antd/dist/antd.css';
@import 'Login.scss';
@import 'Home.scss';
@import 'Layout.scss';
@import 'Review.scss';
@import 'KeywordSalesCorr.scss';
@import 'KeywordSummary.scss';
@import 'PerTimeChart.scss';
@import 'KeywordQtySales.scss';
@import 'ViewPlaceRank.scss';
@import 'QtyChart.scss';
@import 'SalesRadar.scss';
@import 'SalesCompare.scss';
@import 'HotTrend.scss';
@import 'LegalAreaFilter.scss';
@import 'InterestData.scss';
@import 'KeywordTags.scss';
@import 'FranchiseRank.scss';
@import 'DetailModal.scss';
@import 'AreaDatalab.scss';
@import 'CompareKeyword.scss';

*{
  font-family: 'Pretendard';
  ::-webkit-scrollbar{
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.3);
  }
}

html{
  min-height: 100%;
  display: flex;
}

body, #root{
  flex: 1;
  display: flex;
}

.ant-input{
  border-radius: 4px;
  padding: 6px 12px;
  height: 32px;
  box-shadow: none !important;
  font-weight: 500;
  line-height: 20px;
  font-size: 13px;
}

.ant-btn{
  height: 32px;
  min-width: 64px;
  padding: 0 8px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  border-radius: 4px;
}

.spin{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preset_range{
  margin-right: 12px;
}

