.data{
  display: flex;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.chart_box{
  flex: 1;
  padding: 0 48px;
  display: flex;
  flex-flow: column;
  .chart{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.check_box{
  width: 320px;
}