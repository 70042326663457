.modal_flex{
  width: 100%;
  height: 660px;
  display: flex;
}

.modal_rank_box{
  flex: 2;
  display: flex;
  flex-flow: column;
  padding-right: 6px;
}

.modal_qty_box{
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-left: 6px;
}

.table_title{
  font-weight: bold;
  font-size: 16px;
}

.table_box{
  overflow-y: auto;
  .save_csv_box{
    display: flex;
    justify-content: right;
    .save_csv{
      margin-top: 12px;
    }
  }
}