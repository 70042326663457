.double_chart_box{
  flex: 1;
  display: flex;

  .chart_container{
    flex: 1;
    margin: 24px;
    display: flex;
  }

  .chart{
    width: 100%;
  }
}