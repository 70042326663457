.menu{
  width: 256px;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.menu_box{
  overflow-y: auto;
}

.home{
  height: 64px;
  min-height: 64px;
  margin-bottom: 24px;
  padding: 0 34px 0 24px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 8px #f0f1f2;
  clip-path: inset(-5px 0px -5px -5px);

  .brand_name{
    margin-left: 20px;
    font-weight: 700;
    font-size: 14px;
  }
  font-size: 18px;
}

.content{
  .header{
    height: 64px;
    min-height: 64px;
    margin-bottom: 24px;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
    clip-path: inset(-5px -5px -5px 0px);
    background: white;
    z-index: 2;
    position: sticky;
    top: 0;
  }
  position: absolute;
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  left: 256px;
  right: 0;
  top: 0;
  bottom: 0;
  padding-bottom: 24px;
}