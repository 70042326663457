.brand_box{
  margin: auto;
  padding: 0 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .brand_list{
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    .brand{
      padding: 12px 0;
      font-weight: 500;
    }
    width: 100%;
  }

  .sortby{
    padding-top: 20px;
  }

  .logout{
    padding-top: 20px;
  }

  .ant-card-body:before{
    display: none;
  }

  .ant-card-body:after{
    display: none;
  }
}

.switch{
  padding: 24px 100px;
  display: flex;
  justify-content: right;
}

.home_box{
  width: 100%;
}

.trend_box{
  margin: auto;
  padding: 0 100px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
}