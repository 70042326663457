.keyword_tags{
  margin: 24px auto;
  width: 60%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .ant-input-search{
    button{
      min-width: 0;
      padding: 0;
    }
  }

  .tags{
    width: 480px;
    margin-top: 24px;
  }
}