.chart_box{
  table{
    display: flex;
    width: fit-content;
    tr{
      display: block;
      float: left;
    }
    th, td{
      display: block;
    }
    td{
      width: 120px;
    }
    margin-bottom: 12px;
    font-size: 14px;
  }
}

.data_table_box{
  margin: 72px auto 0 auto;
  td{
    width: 100% !important;
  }
}
