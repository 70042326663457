.compare_keyword{
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;

  .header{
    height: 64px;
    min-height: 64px;
    margin-bottom: 24px;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
    clip-path: inset(-5px -5px -5px 0px);
    background: white;
    z-index: 2;
    position: sticky;
    top: 0;

    .spread{
      padding-left: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }

    .header_title{
      font-weight: bold;
      padding-right: 34px;
      width: 232px;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 18px;
      .menu_title{
        margin-left: 20px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .body{
    display: flex;
    flex: 1;

    .keyword_input{
      width: 256px;
      padding: 0 34px;
      display: flex;
      flex-flow: column;
    }

    .keyword_tag{
      margin-bottom: 12px;
      width: 188px;
      height: 32px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 12px;
    }

    .data{
      .table{
        width: 95%;
        margin: 0 auto 24px auto;
        table{
          font-size: 12px;
        }
        .save_csv_box{
          display: flex;
          justify-content: right;
          .save_csv{
            margin: 12px 0 24px 0;
          }
        }
        .modal_trigger{
          cursor: pointer;
        }
      }
    }
  }
}
