.franchise_rank{
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;

  .header{
    height: 64px;
    min-height: 64px;
    margin-bottom: 24px;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 24px;
    clip-path: inset(-5px -5px -5px 0px);
    background: white;
    z-index: 2;
    position: fixed;
    top: 0;

    .spread{
      padding-left: 24px;
      display: flex;
      align-items: center;
      flex: 1;
      .ant-input-search{
        button{
          min-width: 0;
          padding: 0;
        }
      }
    }

    .header_title{
      font-weight: bold;
      padding-right: 34px;
      width: 232px;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 18px;
      .menu_title{
        margin-left: 20px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .body{
    margin-top: 64px;
    height: calc(100vh - 64px);

    .table{
      width: 95%;
      margin: 24px auto 0 auto;

      .save_csv_box{
        display: flex;
        justify-content: right;
        .save_csv{
          margin: 12px 0 24px 0;
        }
      }

    }
  }
}
